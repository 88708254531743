import humps from "humps";
import React from "react";

const responsizeWrapLookupClassNames = {
    '': {
        'left': 'wiml-wrap-left',
        'right': 'wiml-wrap-right'
    },
    'lg': {
        'left': 'wiml-lg-wrap-left',
        'right': 'wiml-lg-wrap-right'
    },
    'md': {
        'left': 'wiml-md-wrap-left',
        'right': 'wiml-md-wrap-right'
    },
    'sm': {
        'left': 'wiml-sm-wrap-left',
        'right': 'wiml-sm-wrap-right'
    },
    'xs': { // xs is default todo make it empty ''
        'left': 'wiml-xs-wrap-left',
        'right': 'wiml-xs-wrap-right'
    }
} as const;

type ResponsizeWrapLookupClassNamesKey = keyof typeof responsizeWrapLookupClassNames;
type PositionKey = keyof typeof responsizeWrapLookupClassNames.xs;

const wrapLookupClassNames = Object.keys(responsizeWrapLookupClassNames) as ResponsizeWrapLookupClassNamesKey[];
const camelizedPropNames = wrapLookupClassNames.reduce((acc, size) => {
    const alignPropName = `wrap-${size}`;
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<ResponsizeWrapLookupClassNamesKey, string>);

// this is a HOC react component that wraps any WIML component with an optional flex parent that provides for wrap
export default function withWrap_v1_2_0(WrappedComponent: React.ComponentType) {
    return function (props: any) {
        const className = _getWrapClassNames_v1_2_0(props);

        return (
            <WrappedComponent {...props} wimlWrapClassName={className} />
        );
    }
}
export function _getWrapClassNames_v1_2_0(props: any) {
    const classNames = wrapLookupClassNames.map((size) => {
        const camelizedPropName = camelizedPropNames[size];
        const propValue = props[camelizedPropName] as PositionKey;
        if (propValue) {
            return [
                size ? `wiml-${size}-wrap` : 'wiml-wrap',
                responsizeWrapLookupClassNames[size][propValue]
            ];
        }
    });

    const className = classNames.flat().filter(Boolean).join(' ');
    return className;
}

